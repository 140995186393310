.btn-entity{
    /*color: #212529;*/
    background-color: #b49594;
    border-color: #b49594;
    box-shadow: 0;
    color: #fff;
}

.btn-entity:hover {
    color: #fff;
    background-color: #966a69;
    border-color: #966a69;
}

.leaflet-container {
    height: 600px;
    width: 100%;
  }